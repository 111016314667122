function _EventTrackingException(event, category, action, label, value, message) {
    this.event = event;
    this.category = category;
    this.action = action;
    this.label = label;
    this.value = value;
    this.message = message || "EventTracking Exception";

    var self = this;
    this.toString = function () {
        return self.message;
    };
}

window.EventTracking = {
    MAPPING: {
        'Lead_story': ['article.leadstory'],
        'FC-Featured_story': ['article.newsItem.compact'],
        'Related_Article_story_box': ['article.relatedStories div.in a.link'],
        'Most_Read_stories_box': ['div.topStories div.in ul li'],
        'Bottom_Article_link': ['.first-related'],
        'ES-Event_Sidebar': ['div.featuredEvents'],
        'E-Event': ['div.eventItem.compact'],
        'Mobile-subscribe-click': ['#mobSubscribeBtn'],
        'Related_Topics_Tags': ['article div.in .tags a'],
        'Nav-Submenu_Cities': ['#subheader .cities_list li a'],
        'Nav-Submenu_Cities_Mobile': ['#subheader .cities_list_compact li a'],
        'Nav-Main': ['#header nav > ul > li > a'],
        'Nav-Main_Dropdowns': ['#header nav .subMenuContent ul li a'],
    },

    track: function (category, action, label, value) {
        if (window.dataLayer === undefined) {
            window.location.href = label;
            return;
        }

        if (window.dataLayer) {
            dataLayer.push({
                'event': action,
                'event_category': category,
                'url': label,
                'value': value
            })
        }
    },

    bootstrap: function () {
        for (var category in EventTracking.MAPPING) {
            var selectors = EventTracking.MAPPING[category];
            var s = "";
            for (var i = 0; i < selectors.length; ++i) {
                if (s.length) {
                    s += ", ";
                }
                var selector = selectors[i];
                s += selector;
                if (!selector.match(/.*a(\.[\w\d\-_]+)?$/)) {
                    s += ' a';
                }
            }

            EventTracking.makeEvent(category, s);
        }

        //also initiate tracking on the seven first positions on index pages
        $('div.singleArticle a.link').click(function (e) {
            var clickLabel = "C" + $(this).index('div.singleArticle a.link');
            EventTracking.track(clickLabel, 'clicked', $(this).attr('href'), 1);
        });

        //also intiate tracking on copy events
        $(window).on("copy", function () {
            EventTracking.track('Content_Copied_to_clipboard', 'clicked', window.location.href, 1);
        });
    },

    makeEvent: function (category, selector) {
        $(selector).click(function (event) {
            event.preventDefault();
            EventTracking.track(category, 'clicked', $(this).attr('href'), 1);

            if (typeof $(this).attr('href') === "undefined") {
            } else {
                window.location.href = $(this).attr('href');
            }

        });
    }
};

EventTracking.bootstrap();